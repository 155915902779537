::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

html {
    font-family: Roboto, sans-serif;
    font-size: 16px !important;
}

.p-tree-filter-container {
    position: relative;

    searchicon {
        .p-tree-filter-icon {
            position: absolute;
            top: 11px;
            left: 10px;
            font-size: 13px;
            right: 0;
            margin: 0;
        }
    }

    input {
        border-radius: 40px;
        padding-left: 30px;
        font-size: 13px;
    }
}

// loader
p-progressspinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.mainWrapper {
    padding: 0px 0px 0 10px;
    display: flex;
    gap: 10px;
    min-height: calc(100vh - 90px);
    background-color: $primary-color;
    height: 100%;
    overflow: auto;

    app-side-nav {
        max-width: 220px;
        display: flex;
        width: 100%;
        overflow: auto;
        flex-direction: column;
        grid-gap: 10px;
        padding-top: 10px;
        height: 100vh;
        position: sticky;
        top: 0;
        z-index: 999;

        .logo {
            max-width: 220px;
            border: 2px solid $sky-blue-color;
            border-radius: 10px;
            padding: 3px 22px 4px;
            display: flex;
        }

        .sideMenu {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 2px solid $light-blue-color-1;
            border-radius: 10px;
            padding: 10px;
            height: calc(100vh - 90px);
            overflow: auto;

            li {
                display: flex;
                grid-gap: 10px;
                flex-direction: column;
                line-height: 24px;
                font-weight: 500;
                width: 100%;
                position: relative;

                .sidebarMenu {
                    display: flex;
                    align-items: center;
                    grid-gap: 10px;
                    padding: 10px;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    p {
                        margin: 0;
                        flex: 1;
                    }

                    &.active {
                        background-color: $sky-light-blue;

                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            border: 2px solid $blue-color;
                            height: 100%;
                        }
                    }

                    .pi-angle-down {
                        &.expand {
                            transform: rotate(-180deg);
                            -webkit-transform: rotate(-180deg);
                            -moz-transform: rotate(-180deg);
                        }
                    }
                }

                ul {
                    li {
                        flex-direction: row;
                        padding-left: 20px;
                        line-height: 30px;
                        align-items: center;
                        padding-bottom: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .contentWrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: auto;
        height: calc(100vh - 10px);
        display: block;

        .pageTitle {
            margin: 0 0 10px;
            font-size: 20px;
            color: $medium-gray;
            opacity: 0.5;
            font-weight: 400;
            display: flex;
            align-items: center;
            grid-gap: 10px;
        }

        .contentSection {
            display: flex;
            background: $silver-color;
            padding: 15px;
            height: calc(100% - 80px);
            border-radius: 5px;
            position: sticky;
            top: 0;
            overflow: auto;

            app-facility-tree {
                background-color: $primary-color;

                p-scrollpanel {
                    width: 100%;
                }
            }

            .p-datatable-wrapper {
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
            }
        }

        app-campus {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
            grid-gap: 20px;
            overflow: hidden;
        }
    }

    app-facility-tree {
        // border-radius: 10px;
        padding: 10px;
        display: flex;
        width: 100%;
        height: 100%;
        // overflow: auto;
        // min-height: calc(100vh - 220px);
    }
}

.geofence-tree {
    p-colorPicker {
        position: absolute;
        right: 0 !important;
        left: inherit;
        top: -30px;

        .p-colorpicker-overlay {
            input {
                height: 10px;
                width: 10px;
            }

            .p-colorpicker-panel {
                left: inherit !important;
                right: 0;
            }
        }
    }
}

// nodata
.nodataFound {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $light1-gray;
    font-size: 18px;
    width: 100%;
}

// map menu
.facilityMenu {
    .p-tree {
        padding: 0;

        p-treenode {
            li {
                &:focus {
                    .p-treenode-content {
                        box-shadow: none;
                    }
                }

                .p-treenode-content {
                    flex-direction: row-reverse;
                    padding: 0;
                    color: $secondary-color;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 500;
                    position: relative;
                    padding-bottom: 15px;
                    padding-left: 25px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 28px;
                        border-left: 3px solid #e2e8f0;
                        left: 6px;
                        z-index: 99;
                        height: 15px;
                        width: 2px;
                    }

                    .ui-treenode-icon {
                        position: absolute;
                        top: 4px;
                        left: 0;
                    }

                    button {
                        margin: 0 !important;

                        &:focus {
                            box-shadow: none !important;
                        }
                    }

                    .p-treenode-label {
                        flex: 1;
                        line-height: 20px;
                    }

                    &.p-highlight {
                        background-color: transparent !important;
                        color: $maroon-color !important;

                        .p-tree-toggler {
                            color: $maroon-color !important;
                        }
                    }
                }
            }
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 !important;

        li {
            display: flex;
            grid-gap: 10px;
            flex-direction: column;
            line-height: 24px;
            font-weight: 500;
            width: 100%;
        }
    }
}

.p-tree-wrapper {
    ul {
        li {
            margin-bottom: 0px;

            button {
                cursor: pointer;
            }

            ul {
                li {
                    padding-left: 15px;

                    .p-treenode-label {
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}

// device menu
app-devices-menu {
    position: absolute;
    right: 15px;
    top: 24px;
    z-index: 9991;

    .deviceList {
        background-color: $primary-color;
        position: absolute;
        right: 0px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 15px;
        min-width: 236px;
        top: 30px;

        li {
            display: flex;
            grid-gap: 10px;
            align-items: center;
            padding-bottom: 20px;

            &:last-child {
                padding-bottom: 0px;
            }

            h3 {
                margin: 0;
                font-weight: 400;
                font-size: 16px;
                flex: 1;
            }
        }
    }
}

.leaflet-control-fullscreen {
    .leaflet-control-fullscreen-button {
        position: relative;
        font-family: primeicons;
        font-style: normal;
        font-weight: 400;

        &:before {
            content: "\ea0a";
        }
    }
}

.campusSection {
    .p-scrollpanel {
        max-height: calc(100vh - 130px);
        overflow: auto;
    }
}

/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: $light-gray-3;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    // background-color: $primary-color;
    transition: 0.4s;
    border-radius: 50%;
}

.deviceListing {
    .slider {
        &.round {
            background-color: $light-gray-3 !important;
            border: 1px solid $light-gray-3 !important;

            &::before {
                background-color: $primary-color !important;
            }
        }
    }
}

.deviceListing {
    input:checked+.slider {
        background-color: $toggle-color !important;
        border: 1px solid $toggle-color !important;
    }
}

.deviceListing {
    input:checked+.slider:before {
        transform: translateX(26px);
    }
}

.tooltip-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    padding: 4px 8px;
    border-radius: 4px;

    .toggle-label {
        font-weight: 700;
        margin-right: 5px;
    }
}

/* Tooltip text */

// .tooltip-text {
//     visibility: hidden;
//     width: 160px;
//     background-color: $medium-gray;
//     color: $primary-color;
//     text-align: center;
//     border-radius: 5px;
//     padding: 5px 0;
//     position: absolute;
//     z-index: 1;
//     right: 42px;
//     opacity: 0;
//     top: -5px;
//     transition: opacity 0.3s;
// }

// .tooltip-container:hover .tooltip-text {
//     visibility: visible;
//     opacity: 1;
// }

/* Tooltip arrow */

// .tooltip-text::after {
//     content: "";
//     position: absolute;
//     top: 8px;
//     /* Bottom of the tooltip */
//     right: -10px;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: transparent transparent transparent $medium-gray;
// }

app-marker,
app-facilities,
app-gateways,
app-groups {
    width: 100%;
}

app-map-view {
    display: flex;
    width: 100%;
    position: relative;
    // border: 2px solid $light-blue-color-1;
    grid-gap: 20px;

    .map-container {
        width: 100%;
        height: 100%;
        background-color: $light-blue-color-2;

        .leaflet-control {
            cursor: pointer;

            &.leaflet-control-attribution {
                display: none;
            }

            &:hover {
                background-color: $light-gray-4 !important;
            }

            &.enableGateway {
                // background-color: rgba(0, 0, 0, 0.13);
                background-color: transparent !important;
                border-radius: 3px;
                padding: 0px !important;

                p {
                    margin: 0;
                    background-color: $light-blue-color-3;
                    border-radius: 3px;
                    color: $medium-gray;
                    font-size: 13px;
                    letter-spacing: 0.5px;
                    font-weight: 600;
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    grid-gap: 10px;
                }
            }
        }

        .leaflet-top,
        .leaflet-bottom {
            &.leaflet-right {
                .leaflet-bar {
                    padding: 0;
                    border-radius: 50px;
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;

                    &:hover,
                    &:focus {
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                        transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                    }
                }
            }
        }
    }
}

app-widgets {
    table {
        tr {

            th,
            td {
                &:last-child {
                    width: 150px;
                }
            }
        }
    }
}

// geofence popup

.geofence-dialog-wrapper {

    .p-dialog-content {
        padding-bottom: 0;

        .p-fluid {
            display: flex;
            gap: 14px;

            .p-field {
                display: flex;
                flex-direction: column;
                gap: 10px;
                flex: 1;

                input {
                    padding: 20px;
                    border-radius: 15px;
                    border: 1px solid #e2e8f0;
                    color: #000000;
                }
            }
        }

    }
}

.category-wrapper {
    p-dropdown {
        .p-dropdown.p-component {
            flex: 1;
            border-radius: 15px;

            .p-dropdown-label {
                height: 42px;
                font-size: 14px;
            }
        }
    }
}

.delete-icon {
    i {
        color: #f00;
    }
}

.geofence-context-menu {
    position: absolute;
    background: #fff;
    padding: 10px 0;
    z-index: 1000;
    display: flex !important;
    grid-gap: 8px;
    flex-direction: column;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.4);
    border-radius: 4px;

    .geofence-context-menu-items {
        display: flex;
        align-items: center;
        grid-gap: 6px;
        color: #222;
        font-size: 12px;
        padding: 0 15px;
        line-height: 20px;
        cursor: pointer;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &:hover{
            background-color: #f4f4f4;
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
        }
    }
}
#map{
    .edit-control{
        display: flex;
        gap: 7px;
        border: none;
        .customBtn {
            padding: 8px 12px;
            border: 1px solid #ccc;
            border-radius: 3px;
            background: #fff;
            color: #000;
            cursor: pointer;
            &:hover {
                background: #f4f4f4;
            }
        }
    }
}